import { Route, Routes } from 'react-router-dom'

import MainPage from '../../pages/Main/Main'
import Thanks from '../../pages/Thanks/Thanks'
import '../../fonts/verahumana.scss';
import './App.scss'

function App() {
  function reveal() {
    var reveals = document.querySelectorAll('.reveal')

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight
      var elementTop = reveals[i].getBoundingClientRect().top
      var elementVisible = 150

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active')
      } else {
        reveals[i].classList.remove('active')
      }
    }
  }

  window.addEventListener('scroll', reveal)

  return (
    <>
      <Routes>
        <Route path='/' element={<MainPage />} />
        <Route path='thanks' element={<Thanks />} />

        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </>
  )
}

export default App
