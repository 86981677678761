import React, { useEffect, useState } from 'react'
import './Gallery.scss'
import photo1 from '../../image/example/polotno/1.jpg'
import photo2 from '../../image/example/polotno/2.jpg'
import photo3 from '../../image/example/polotno/3.jpg'
import photo4 from '../../image/example/polotno/4.jpg'
import photo5 from '../../image/example/polotno/5.jpg'
import photo6 from '../../image/example/polotno/6.jpg'
import photo7 from '../../image/example/polotno/7.jpg'
import photo8 from '../../image/example/polotno/8.jpg'
import justMerge1 from '../../image/example/just-merge/1.jpg'
import justMerge2 from '../../image/example/just-merge/2.jpg'
import collage1 from '../../image/example/coladj/1.jpg'
import collage2 from '../../image/example/coladj/2.jpg'
import collage3 from '../../image/example/coladj/3.jpg'
import collage4 from '../../image/example/coladj/4.jpg'
import time1 from '../../image/example/time/1.jpg'
import time2 from '../../image/example/time/2.jpg'
import time3 from '../../image/example/time/3.jpg'
import time4 from '../../image/example/time/4.jpg'
import art1 from '../../image/example/art/1.jpg'
import art2 from '../../image/example/art/2.jpg'
import art3 from '../../image/example/art/3.jpg'
import art4 from '../../image/example/art/4.jpg'
import Loading from '../Loading/Loading'

const categories = [
  { id: 'photo', label: 'Фото на полотні' },
  { id: 'just-comb', label: 'Просте поєднання' },
  { id: 'collage', label: 'Колаж' },
  { id: 'time', label: 'З годинником' },
  { id: 'art', label: 'Арт поєднання' }
]

const images = [
  { src: photo1, category: 'photo' },
  { src: photo2, category: 'photo' },
  { src: photo3, category: 'photo' },
  { src: photo4, category: 'photo' },
  { src: photo5, category: 'photo' },
  { src: photo6, category: 'photo' },
  { src: photo7, category: 'photo' },
  { src: photo8, category: 'photo' },
  { src: justMerge1, category: 'just-comb' },
  { src: justMerge2, category: 'just-comb' },
  { src: collage1, category: 'collage' },
  { src: collage2, category: 'collage' },
  { src: collage3, category: 'collage' },
  { src: collage4, category: 'collage' },
  { src: time1, category: 'time' },
  { src: time2, category: 'time' },
  { src: time3, category: 'time' },
  { src: time4, category: 'time' },
  { src: art1, category: 'art' },
  { src: art2, category: 'art' },
  { src: art3, category: 'art' },
  { src: art4, category: 'art' }
]

const Gallery = () => {
  const [activeCategory, setActiveCategory] = useState('photo')
  const [loading, setLoading] = useState(true)

  const filteredImages = images.filter(
    (image) => image.category === activeCategory
  )

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => setLoading(false), 1000)

    return () => clearTimeout(timer)
  }, [activeCategory])

  return (
    <div className='gallery reveal'>
      <h2 className='gallery__title'>Приклади робіт</h2>

      <div className='categories'>
        {categories.map((category) => (
          <button
            key={category.id}
            className={`category-btn ${
              activeCategory === category.id ? 'active' : ''
            }`}
            onClick={() => setActiveCategory(category.id)}
          >
            {category.label}
          </button>
        ))}
      </div>

      {/* <div className='image-grid-container'> */}
      {loading ? (
        <div className='spinner'>
          <Loading label={'Завантаження...'} />
        </div>
      ) : null}
      <div className={`image-grid ${loading ? 'image-grid-hide' : ''}`}>
        {filteredImages.map((image, index) => (
          <div key={index} className='image-item'>
            <img src={image.src} alt={`Gallery item ${index}`} loading='lazy' />
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
  )
}

export default Gallery
