// import React, { useState, useEffect } from 'react'
// import './Popup.scss'
// import gift from '../../image/gift-box.png'
// import line from '../../image/line-popup.png'
// import viber from '../../image/viber.png'
// import telegram from '../../image/telegram.png'

// const Popup = () => {
//   const [isVisible, setIsVisible] = useState(false)

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY
//       const triggerPoint = document.body.scrollHeight / 2

//       if (scrollPosition >= triggerPoint) {
//         setIsVisible(true)
//       }
//     }

//     window.addEventListener('scroll', handleScroll)

//     return () => {
//       window.removeEventListener('scroll', handleScroll)
//     }
//   }, [])

//   useEffect(() => {
//     if (isVisible) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'auto'
//     }
//   }, [isVisible])

//   return (
//     <div className={`popup ${isVisible ? 'popup--visible' : ''}`}>
//       <div className='popup__content'>
//         <img className='popup__gift popup__gift--top' src={gift} alt='gift' />
//         <img className='popup__line' src={line} alt='line' />
//         <img
//           className='popup__gift popup__gift--bottom'
//           src={gift}
//           alt='gift'
//         />
//         <h2 className='popup__title'>СТВОРИ СПРАВЖНЮ КАРТИНУ ПО ФОТО</h2>
//         <p className='popup__description'>
//           При покупці 2-х картин, 3-тя в подарунок
//         </p>
//         <div className='popup__buttons'>
//           <a className='popup__button popup__button--viber' href='#'>
//             <img src={viber} className='popup__button--icon' alt='viber' />
//             Viber
//           </a>
//           <a className='popup__button popup__button--telegram' href='#'>
//             <img src={telegram} className='popup__button--icon' alt='telegram' />
//             Telegram
//           </a>
//         </div>
//         <p className='popup__thank-you' onClick={() => setIsVisible(false)}>
//           Дякую, мені не потрібен подарунок
//         </p>
//       </div>
//     </div>
//   )
// }

// export default Popup

import React, { useState, useEffect } from 'react'
import './Popup.scss'
import gift from '../../image/gift-box.png'
import line from '../../image/line-popup.png'
import viber from '../../image/viber.png'
import telegram from '../../image/telegram.png'

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [isClosed, setIsClosed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const triggerPoint = document.body.scrollHeight / 2

      if (scrollPosition >= triggerPoint && !isClosed) {
        setIsVisible(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isClosed])

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isVisible])

  const handleClose = () => {
    setIsVisible(false)
    setIsClosed(true) // Позначаємо, що попап закрито
  }

  return (
    <div className={`popup ${isVisible ? 'popup--visible' : ''}`}>
      <div className='popup__content'>
        <img className='popup__gift popup__gift--top' src={gift} alt='gift' />
        <img className='popup__line' src={line} alt='line' />
        <img
          className='popup__gift popup__gift--bottom'
          src={gift}
          alt='gift'
        />
        <h2 className='popup__title'>СТВОРИ СПРАВЖНЮ КАРТИНУ ПО ФОТО</h2>
        <p className='popup__description'>
          При покупці 2-х картин, 3-тя в подарунок
        </p>
        <div className='popup__buttons'>
          <a
            className='popup__button popup__button--viber'
            href='viber://chat?number=%2B380957204767'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={viber} className='popup__button--icon' alt='viber' />
            Viber
          </a>
          <a
            className='popup__button popup__button--telegram'
            href='https://t.me/sofiatsiupa'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={telegram}
              className='popup__button--icon'
              alt='telegram'
            />
            Telegram
          </a>
        </div>
        <p className='popup__thank-you' onClick={handleClose}>
          Дякую, мені не потрібен подарунок
        </p>
      </div>
    </div>
  )
}

export default Popup
