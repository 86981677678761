import './OurServices.scss'
import art from '../../image/services/art/art.png'
import colague from '../../image/services/colague/colague.png'
import blackAndWhite from '../../image/services//black-white/black-white.png'
import interier from '../../image/services/interier/IMG_5517.JPG'
import loveIs from '../../image/services/love-is/love-is.png'
import changeBg from '../../image/services/picture-with-exchange-phon/change_bg.png'
import simple from '../../image/services/simpe-picture-from-photo/simple.jpg'
import standart from '../../image/services/standart-mix/standart.jpg'
import simsons from '../../image/services/simsons/simsons.png'
import time from '../../image/services/picture-with-clock/time.png'

const data = [
  {
    img: loveIs,
    button: 'Love is'
  },

  {
    img: simsons,
    button: 'Сімпсони'
  },
  {
    img: simple,
    button: 'Просто картина',
    classes: 'services__img-rounded'
  },
  {
    img: colague,
    button: 'Колаж'
  },
  {
    img: time,
    button: 'Картина з годинником'
  },
  {
    img: interier,
    button: "Інтер'єрна картина",
    classes: 'services__img-rounded'
  },
  {
    img: blackAndWhite,
    button: 'З чорно-білої – кольорова'
  },
  {
    img: art,
    button: 'Арт-поєднання'
  },
  {
    img: changeBg,
    button: 'Картина з заміною фону'
  },
  {
    img: standart,
    button: 'Стандартне поєднання',
    classes: 'services__img-standart',
    classes2: 'services__img-center'
  }
]

const OurServices = ({ modal }) => {
  const renderServices = (data) => {
    return data.map((item) => {
      const { img, button, classes, classes2 } = item

      return (
        <li className={`services__item ${classes2}`} key={button}>
          <div className='services__image-container'>
            <img src={img} className={`services__img ${classes}`} alt={button} />
          </div>
          <button
            onClick={() => modal(true)}
            className='button services__description'
          >
            {button}
          </button>
        </li>
      )
    })
  }

  return (
    <>
      <a id='services'></a>
      <section className='services reveal'>
        <div className='services__bg1'></div>
        <div className='services__bg2'></div>

        <div className='services__container'>
          <h2 className='services__title'>Послуги</h2>

          <ul className='services__list'>{renderServices(data)}</ul>
        </div>
      </section>
    </>
  )
}

export default OurServices
